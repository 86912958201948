import useError from 'hooks/use-error'
import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'

type ErrorMessagePropsType = {
    error?: unknown
    className?: string
}

const ErrorMessage = ({ error, className }: ErrorMessagePropsType) => {
    const { errorMessage, setError } =useError()

    useEffect(()=>{
        setError(error)
    },[error, setError])

    if(!errorMessage) return null

    return (
        <Alert variant="danger" className={`no-print ${className ? className : ''}`}>
            {errorMessage}
        </Alert>
    )
}

export default ErrorMessage
