import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IDescargarMoviminetosInventarioRequest from "interfaces/service/requests/IDescargarMoviminetosInventarioRequest";

export async function movimientos(data: IDescargarMoviminetosInventarioRequest){
    const result = await axios.get(API_RESOURCES.INVENTARIO_DESCARGAS.BASE+'/movimientos', {
        params: {
            ...data
        },
        responseType: 'blob'
    })

    return result
}