import { makeAutoObservable, runInAction } from "mobx"
import APP_MODES from "types/app-modes";
import { queryClient } from "..";
import api from "../api/api";
import IUser from "../interfaces/service/models/IUser";
import { RootStore } from "./root-store";

const LSKEY = {
    TOKEN: 'token',
    USER: 'user',
}

class AuthStore {
    user?: IUser;
    token?: string
    
    mode: APP_MODES
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.loadFromLocalStorage()
        this.mode = "PRODUCTIVIDAD"
        this.rootStore = rootStore
    }

    async login(email: string, password: string, mode: APP_MODES = "PRODUCTIVIDAD") {
        const result = await api.auth.loginRequest(email, password)
        if (result.status === 200) {
            runInAction(()=>{
                this.token = result.data.access_token
                this.user = result.data.user
                this.mode = mode
            })

            localStorage.setItem(LSKEY.TOKEN, result.data.access_token)
            localStorage.setItem(LSKEY.USER, JSON.stringify(result.data.user))

            if(!result.data.user.is_admin){
                this.rootStore.syncStore.startSyncronization()
            }
        }
        return result
    }

    async logout() {
        queryClient.removeQueries()

        this.token = undefined
        this.user = undefined
        localStorage.removeItem(LSKEY.TOKEN)
        localStorage.removeItem(LSKEY.USER)

        this.rootStore.syncStore.clear()
    }

    loadFromLocalStorage() {
        const lsToken = localStorage.getItem(LSKEY.TOKEN)
        const user = JSON.parse(localStorage.getItem(LSKEY.USER)!)
        if (lsToken && lsToken.length && user) {
            this.token = lsToken
            this.user = user
        }
    }

    get loginValid() {
        return !!(this.token && this.user)
    }
}

export default AuthStore