import ButtonWithLoading from 'components/button-with-spinner';
import React, { useState } from 'react';
import ErrorMessage from '../../components/error-message';

type LoginFormPropsTypes = {
    isLoginIn?: boolean
    onLoginClick?: (email: string, password: string) => void
    error?: string
}

const LoginForm = ({ isLoginIn, onLoginClick, error }: LoginFormPropsTypes) => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault()
        onLoginClick?.(email, password)
    }

    return <div className="card-body px-4 px-lg-5 text-black">
        <form onSubmit={handleSubmit}>
            <fieldset disabled={isLoginIn}>
                <div className="d-flex align-items-center pb-1">
                    <img className="mx-auto d-block pb-1" src="/img/Logo.svg" height="80" alt="Logo" />
                </div>
                <h3 className="text-center text-primary">Iniciar Sesion<br /></h3>
                <p className="fw-normal mb-3 pb-3 text-center text-muted">
                    Bienvenido(a) al sistema de visita médica de <br />
                    <b>SNC PHARMA®</b>
                </p>
                <div className="form-outline mb-3">
                    <label className="form-label">Correo Electrónico</label>
                    <input
                        type="email"
                        className="form-control form-control-lg bordeiz"
                        placeholder="Ejemplo@sncpharma.com"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div className="form-outline mb-3">
                    <label className="form-label">Contraseña</label>
                    <input
                        type="password"
                        className="form-control form-control-lg bordeiz"
                        placeholder="Ingrese su contraseña"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div className="pt-1 mb-4">
                    <ButtonWithLoading
                        isLoading={isLoginIn}
                        buttonProps={{ type: "submit", size: "lg" }}>
                        Ingresar
                    </ButtonWithLoading>
                </div>
                {error && <ErrorMessage error={error} className="w-100" />}

            </fieldset>
        </form>
    </div>;
};

export default LoginForm;
