import axios from "axios"

function extractError(err: unknown): string{
    if(typeof err === "string"){
        return err
    }

    if(axios.isAxiosError(err)){
        if(err.response) {
            if(err.response.data.message){
                return err.response.data.message
                
            }
            
            return err.response.data
            
        }
        
        if(err.message === "Network Error"){
            return  "Ha ocurrido un error de red"
        }

        return err.message
    }

    if(err instanceof Error){
        return err.message
        
    }

    return "Ha ocurrido un error desconocido"
}

export default extractError