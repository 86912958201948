import axios from "axios"
import API_RESOURCES from "constants/service-resources"
import IPaginatedResponse from "interfaces/service/models/IPaginatedResponse"
import IProducto from "interfaces/service/models/IProducto"
import IPaginatedRequest from "interfaces/service/requests/IPaginatedRequest"


export async function getAll(){
    const result = await axios.get<IProducto[]>(API_RESOURCES.PRODUCTOS.BASE)
    return result.data
}

export async function getPaginated(params: IPaginatedRequest){
    const result = await axios.get<IPaginatedResponse<IProducto>>(API_RESOURCES.PRODUCTOS.BASE+'/paginated', {
        params: {
            ...params
        }
    })
    return result.data
}

export async function getDetails(id: IProducto["id"]){
    const result = await axios.get<IProducto>(API_RESOURCES.PRODUCTOS.BASE+'/'+id)
    return result.data
}

export async function create(data: IProducto){
    const result = await axios.post(API_RESOURCES.PRODUCTOS.BASE, data)
    return result
}

export async function update(params: {id: number,data: IProducto}){
    const result = await axios.put(API_RESOURCES.PRODUCTOS.BASE+'/'+params.id, params.data)
    return result
}

export async function destoy(id: number){
    const result = await axios.delete(API_RESOURCES.PRODUCTOS.BASE+'/'+id)
    return result
}