import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IFromToFilter from "interfaces/app/IFromToFilter";
import IEstadisticaDetalladaRequest from "interfaces/service/requests/IEstadisticaDetalladaRequest";
import qs from 'qs'

export async function actividades(data: IFromToFilter) {
    const result = await axios.get(API_RESOURCES.REPORTES.BASE + '/actividades', {
        params: {
            ...data
        },
        responseType: 'blob'
    })

    return result
}
export async function estadisticaDetallada(data: IEstadisticaDetalladaRequest) {
    const result = await axios.get(API_RESOURCES.REPORTES.BASE + '/estadisticadetallada', {
        params: {
            ...data
        },
        responseType: 'blob'
    })

    return result
}

export async function medicos(filter?: object) {
    const query = {
        filter
    }
    const filterString = qs.stringify(query, {
        arrayFormat: 'brackets'
    })
    const result = await axios.get(API_RESOURCES.REPORTES.BASE + '/medicos?' + filterString, {
        responseType: 'blob'
    })

    return result
}

export async function clientes(filter?: object) {
    const query = {
        filter
    }
    const filterString = qs.stringify(query, {
        arrayFormat: 'brackets'
    })
    const result = await axios.get(API_RESOURCES.REPORTES.BASE + '/clientes?' + filterString, {
        responseType: 'blob'
    })

    return result
}