import * as actividades from './actividades'
import * as auth from './auth'
import * as ciclos from './ciclos'
import * as clientes from './clientes'
import * as estaditicas from './estadisticas'
import * as eventos from './eventos'
import * as medicos from './medicos'
import * as metas from './metas'
import * as opciones from './opciones'
import * as productos from './productos'
import * as reportes from './reportes'
import * as revisitas from './revisitas'
import * as sync from './sync'
import * as ubicaciones from './ubicaciones'
import * as visitadores from './visitadores'
import * as visitasHospitales from './visitas-hospitaes'
import * as asignaciones from './asignaciones'
import * as inventario from './inventario'
import * as inventarioDescargas from './inventario-descargas'
import * as transferencias from './transferencias'

const api = {
    auth,
    visitadores,
    medicos,
    actividades,
    visitasHospitales,
    clientes,
    productos,
    estaditicas,
    opciones,
    metas,
    reportes,
    sync,
    eventos,
    ciclos,
    revisitas,
    ubicaciones,
    asignaciones,
    inventario,
    inventarioDescargas,
    transferencias
}

export default api 