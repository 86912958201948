import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import ICliente from "interfaces/service/models/ICliente";
import IMedico from "interfaces/service/models/IMedico";
import IProducto from "interfaces/service/models/IProducto";

export async function medicos() {
    const result = await axios.get<IMedico[]>(API_RESOURCES.SYNC.BASE + '/medicos')
    return result.data
}

export async function clientes() {
    const result = await axios.get<ICliente[]>(API_RESOURCES.SYNC.BASE + '/clientes')
    return result.data
}

export async function productos() {
    const result = await axios.get<IProducto[]>(API_RESOURCES.SYNC.BASE + '/productos')
    return result.data
}