import axios from "axios";
import { makeAutoObservable } from "mobx";
import { RootStore } from "./root-store";
import api from "api/api";


class ConnectionStatusStore {

    runningFistOnlineCheck: boolean
    isOnline: boolean
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.isOnline = false
        this.runningFistOnlineCheck = true
        this.doFirstOnlineCheck()
        this.rootStore = rootStore
    }

    private async doFirstOnlineCheck() {
        try {
            this.runningFistOnlineCheck = true;
            await this.checkIfOnline()
            await this.sendLocationIfNonAdmin()
        } catch (err) {

        } finally {
            this.runningFistOnlineCheck = false
        }
    }

    private async checkIfOnline() {
        try {
            await axios.get('ping');
            this.isOnline = true
        } catch (error) {
            this.isOnline = false
        }
    }

    private async sendLocationIfNonAdmin() {
        const user = this.rootStore.authStore.user
        if (!user) return;
        if (user.is_admin) return; // Los admin no envian locación
        /* if (!navigator.geolocation) {
            console.log("Geolocation no soportada por el navegador")
            return;
        }

        navigator.geolocation.getCurrentPosition(async (position) => {
            await api.ubicaciones.registrarUbicacion({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            })
        }); */
    }
}

export default ConnectionStatusStore