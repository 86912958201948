import { observer } from 'mobx-react-lite';
import React from 'react';
import './app.scss';
import UnauthenticatedContent from './components/unauthenticated-content';
import useRootStore from './hooks/use-root-store';
const AuthenticatedContent = React.lazy(() => import("./components/authenticated-content"));


function App() {
  const { authStore } = useRootStore()

  if(authStore.loginValid){
    return <AuthenticatedContent/>
  }

  return (
    <UnauthenticatedContent/>
  );
}

export default observer(App);