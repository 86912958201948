import axios from "axios"
import { useState } from "react"
import extractError from "util/extract-error"

const useError = () => {
    const [errorMessage, seterrorMessage] = useState<string | undefined>(undefined)
    
    function setError(err: unknown){
        if(err === undefined || err === null){
            return seterrorMessage(undefined)   
        }
        seterrorMessage(extractError(err))
    }


    return {
        errorMessage,
        setError
    }
}

export default useError