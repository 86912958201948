const BASE_RESOURCES = {
    AUTH: "/auth",
    VISITADORES: "/visitadores",
    MEDICOS: "/medicos",
    ACTIVIDADES: "/actividades",
    VISITAS_HOSPITALES: "/visitas-hospitales",
    CLIENTES: "/clientes",
    PRODUCTOS: "/productos",
    ESTADISTICAS: "/estadisticas",
    OPCIONES: "opciones",
    METAS: "/metas",
    REPORTES: "/reportes",
    SYNC: "/sync",
    EVENTOS: "/eventos",
    CICLOS: "/ciclos",
    REVISITAS: "/revisitas",
    UBICACIONES: "/ubicaciones",
    ASIGNACIONES: "/asignaciones",
    INVENTARIO: "/inventario",
    INVENTARIO_DESCARGAS: "/inventario-descargas",
    TRANSFERENCIAS: "/transferencias"
}

const API_RESOURCES = {
    AUTH: {
        BASE: BASE_RESOURCES.AUTH,
    },
    VISITADORES: {
        BASE: BASE_RESOURCES.VISITADORES
    },
    MEDICOS: {
        BASE: BASE_RESOURCES.MEDICOS
    },
    TRANSFERENCIAS: {
        BASE: BASE_RESOURCES.TRANSFERENCIAS,
        MEDICOS: BASE_RESOURCES.TRANSFERENCIAS + '/medicos',
        CLIENTES: BASE_RESOURCES.TRANSFERENCIAS + '/clientes',
    },
    ACTIVIDADES: {
        BASE: BASE_RESOURCES.ACTIVIDADES,
        ADMIN: BASE_RESOURCES.ACTIVIDADES + '/admin'
    },
    VISITAS_HOSPITALES: {
        BASE: BASE_RESOURCES.VISITAS_HOSPITALES
    },
    CLIENTES: {
        BASE: BASE_RESOURCES.CLIENTES,
    },
    PRODUCTOS: {
        BASE: BASE_RESOURCES.PRODUCTOS
    },
    ESTADISTICAS: {
        BASE: BASE_RESOURCES.ESTADISTICAS
    },
    OPCIONES: {
        BASE: BASE_RESOURCES.OPCIONES
    },
    METAS: {
        BASE: BASE_RESOURCES.METAS
    },
    REPORTES: {
        BASE: BASE_RESOURCES.REPORTES
    },
    SYNC: {
        BASE: BASE_RESOURCES.SYNC
    },
    EVENTOS: {
        BASE: BASE_RESOURCES.EVENTOS
    },
    CICLOS: {
        BASE: BASE_RESOURCES.CICLOS
    },
    REVISITAS: {
        BASE: BASE_RESOURCES.REVISITAS
    },
    UBICACIONES: {
        BASE: BASE_RESOURCES.UBICACIONES
    },
    ASIGNACIONES: {
        BASE: BASE_RESOURCES.ASIGNACIONES
    },
    INVENTARIO: {
        BASE: BASE_RESOURCES.INVENTARIO
    },
    INVENTARIO_DESCARGAS: {
        BASE: BASE_RESOURCES.INVENTARIO_DESCARGAS
    }
}

export default API_RESOURCES