import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RootStore } from './stores/root-store';
import RootStoreContext from './contexts/root-store-context';
import { BrowserRouter } from 'react-router-dom';
import './axios-config'
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CenteredSpinner from 'components/centered-spinner';
import { register as registerServiceWorker } from './serviceWorkerRegistration';
import { ReactQueryDevtools } from 'react-query/devtools'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export const rootStore = new RootStore();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RootStoreContext.Provider value={rootStore}>
        <BrowserRouter>
          <Suspense fallback={<CenteredSpinner />}>
            <App />
            <ToastContainer />
          </Suspense>
        </BrowserRouter>
      </RootStoreContext.Provider>
      <ReactQueryDevtools initialIsOpen={false}  position="bottom-right"/>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();