import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IActividad from "interfaces/service/models/IActividad";
import IActividadResumen from "interfaces/service/models/IActividadResumen";
import IOpcionesActividad from "interfaces/service/models/IOpcionesActividad";
import { dateToYYYMMDD } from "util/date";

export async function getByDate(date: Date) {
    const formatedDate = dateToYYYMMDD(date)
    const result = await axios.get<IActividad>(`${API_RESOURCES.ACTIVIDADES.BASE}/byDate/${formatedDate}`)
    return result.data
}

export async function createActividad(data: IActividad) {
    const result = await axios.post(API_RESOURCES.ACTIVIDADES.BASE, data)
    return result
}

export async function getPorVisitadorFecha(params: { id_visitador: string, fecha: Date }) {
    const formatedDate = dateToYYYMMDD(params.fecha)
    const result = await axios.get<IActividad>(
        `${API_RESOURCES.ACTIVIDADES.ADMIN}/porVisitadorFecha/${params.id_visitador}/${formatedDate}`
    )
    return result.data
}

export async function getResumenActividadesVisitador(id_visitador: number) {
    const result = await axios.get<IActividadResumen[]>(
        `${API_RESOURCES.ACTIVIDADES.ADMIN}/resumenVisitador/${id_visitador}`
    )
    return result.data
}

export async function getOpciones() {
    const result = await axios.get<IOpcionesActividad>(API_RESOURCES.ACTIVIDADES.BASE + '/opciones')
    return result.data
}

export async function porId(id_actividad: number) {
    const result = await axios.get<IActividad>(
        `${API_RESOURCES.ACTIVIDADES.BASE}/${id_actividad}`
    )
    return result.data
}