import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import InvMuestrasResumen from "interfaces/service/models/IInvMuestrasResumen";
import IResumenExistenciasMuestrasVisitadorResponse from "interfaces/service/responses/IResumenExistenciasMuestrasVisitadorResponse";

export async function getResumenVisitadore(user_id: number) {
    const result = await axios.get<IResumenExistenciasMuestrasVisitadorResponse>(`${API_RESOURCES.INVENTARIO.BASE}/resumen-existencias-visitrador`, {
        params: {
            user_id
        }
    })
    return result.data
}

export async function getResumenGeneral() {
    const result = await axios.get<InvMuestrasResumen[]>(`${API_RESOURCES.INVENTARIO.BASE}/existencias-actuales`)
    return result.data
}