import CONFIRMS from "./confirms";
import ERRORS from "./errors";
import VALIDATIONS from "./validations";

const TEXTS = {
    ERRORS,
    VALIDATIONS,
    CONFIRMS
}

export default TEXTS