import React from 'react'
import AppSpinner from './app-spinner'

const CenteredSpinner = () => {
    return (
        <div className="w-100 d-flex justify-content-center mt-4">
            <AppSpinner/>
        </div>
    )
}

export default CenteredSpinner