import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IUbicacion from "interfaces/service/models/IUbicacion";
import IUbicacionRegistroRequest from "interfaces/service/requests/IUbicacionRegistroRequest";
import IUbicacionesQueryRequest from "interfaces/service/requests/IUbicacionesQueryRequest";

export async function getPorUsuarioYFechas(params: IUbicacionesQueryRequest) {
    const result = await axios.get<IUbicacion[]>(API_RESOURCES.UBICACIONES.BASE, {
        params
    })

    return result.data
}

export async function registrarUbicacion(data: IUbicacionRegistroRequest) {
    return await axios.post(API_RESOURCES.UBICACIONES.BASE, data)
}