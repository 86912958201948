import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IMetaMensualForm from "interfaces/app/IMetaMensualForm";
import IMetaMensual from "interfaces/service/models/IMetaMensual";

export async function establecer(formData: IMetaMensualForm) {
    const metaMensual: IMetaMensual = {
        user_id: formData.user_id,
        mes: formData.mesAno.getMonth() + 1,
        ano: formData.mesAno.getFullYear(),
        meta_cli: formData.meta_cli,
        meta_med: formData.meta_med,
        meta_med_hosp: formData.meta_med_hosp
    }

    const result = axios.post(API_RESOURCES.METAS.BASE + '/establecer', metaMensual)
    return result
}

export async function metasVisitadorFecha(visitador_id: number, fecha: Date) {
    const mes = fecha.getMonth() + 1
    const ano = fecha.getFullYear()

    const result = await axios.get<IMetaMensual>(API_RESOURCES.METAS.BASE + '/metas-visitador', {
        params: {
            visitador_id,
            mes,
            ano
        }
    })

    return result.data
}