import format from "date-fns/format"
import getDay from "date-fns/getDay";

export function dateToYYYMMDD(date: Date) {
    return format(date, 'yyyy-MM-dd');
}

export function fullDateTimeAMPM(date: Date) {
    return format(date, "dd-MM-yyyy hh:mm aaaaa'm'")
}

export function formatDateAsDisplayDate(dateStr: string) {
    if (!dateStr) return "Conversión de fecha fallida. El parametro dateStr no está presente";
    // eslint-disable-next-line no-useless-escape
    const date = new Date(dateStr.replace(/-/g, '\/').replace(/T.+/, ''))
    return format(date, "dd-MM-yyyy")
}

export function dateToMMYYY(date: Date) {
    return format(date, "MM-yyyy")
}

export function dateIsWeekend(date: Date) {
    const day = getDay(date);
    return day === 0 || day === 6
}