import axios from "axios";
import API_RESOURCES from "../constants/service-resources";
import IPaginatedResponse from "../interfaces/service/models/IPaginatedResponse";
import IVisitador from "../interfaces/service/models/IVisitador";
import IPaginatedRequest from "../interfaces/service/requests/IPaginatedRequest";

export async function getVisitadores(params: IPaginatedRequest) {
    const result = await axios.get<IPaginatedResponse<IVisitador>>(API_RESOURCES.VISITADORES.BASE, {
        params: {
            ...params
        }
    })
    return result.data
}

export async function getDatallesVisitador(idVisitador: IVisitador["id"]){
    const result = await axios.get<IVisitador>(API_RESOURCES.VISITADORES.BASE+'/'+idVisitador)
    return result.data
}

export async function registrarVisitador(data: IVisitador){
    const result = await axios.post(API_RESOURCES.VISITADORES.BASE, data)
    return result
}

export async function actualizarVisitado(params: {id: number,data: IVisitador}){
    const result = await axios.put(API_RESOURCES.VISITADORES.BASE+'/'+params.id, params.data)
    return result
}

export async function eliminarVisitardor(id: number){
    const result = await axios.delete(API_RESOURCES.VISITADORES.BASE+'/'+id)
    return result
}