import axios from "axios"
import API_RESOURCES from "constants/service-resources"
import ICiclo from "interfaces/service/models/ICiclo"
import IPaginatedResponse from "interfaces/service/models/IPaginatedResponse"
import IPaginatedRequest from "interfaces/service/requests/IPaginatedRequest"

export async function getAll() {
    const result = await axios.get<ICiclo[]>(API_RESOURCES.CICLOS.BASE)
    return result.data
}

export async function getPaginated(params: IPaginatedRequest) {
    const result = await axios.get<IPaginatedResponse<ICiclo>>(API_RESOURCES.CICLOS.BASE + '/paginated', {
        params: {
            ...params
        }
    })
    return result.data
}

export async function getDetails(id: ICiclo["id"]){
    const result = await axios.get<ICiclo>(API_RESOURCES.CICLOS.BASE+'/'+id)
    return result.data
}

export async function create(data: ICiclo) {
    const result = await axios.post(API_RESOURCES.CICLOS.BASE, data)
    return result
}

export async function update(params: { id: number, data: ICiclo }) {
    const result = await axios.put(API_RESOURCES.CICLOS.BASE + '/' + params.id, params.data)
    return result
}