import axios from "axios"
import API_RESOURCES from "constants/service-resources"
import ITransferClientsLoteVisitadorRequest from "interfaces/service/requests/ITransferClientsLoteVisitadorRequest"
import ITransferClientsVisitadorVisitadorRequest from "interfaces/service/requests/ITransferClientsVisitadorVisitadorRequest"
import ITransferMedLoteVisitadorRequest from "interfaces/service/requests/ITransferMedLoteVisitadorRequest"
import ITransferMedVisitadorVisitadorRequest from "interfaces/service/requests/ITransferMedVisitadorVisitadorRequest"

export async function tranferenciaMedicosVisitadorVisitador(data: ITransferMedVisitadorVisitadorRequest) {
    const result = await axios.post(API_RESOURCES.TRANSFERENCIAS.MEDICOS + '/visitador-visitador', data)
    return result.data
}

export async function tranferenciaMedicosLoteVisitador(data: ITransferMedLoteVisitadorRequest) {
    const result = await axios.post(API_RESOURCES.TRANSFERENCIAS.MEDICOS + '/lote-visitador', data)
    return result.data
}

export async function clientesMedicosVisitadorVisitador(data: ITransferClientsVisitadorVisitadorRequest) {
    const result = await axios.post(API_RESOURCES.TRANSFERENCIAS.CLIENTES + '/visitador-visitador', data)
    return result.data
}

export async function clientesMedicosLoteVisitador(data: ITransferClientsLoteVisitadorRequest) {
    const result = await axios.post(API_RESOURCES.TRANSFERENCIAS.CLIENTES + '/lote-visitador', data)
    return result.data
}