import Dexie, { Table } from 'dexie';
import IActividad from 'interfaces/service/models/IActividad';
import ICliente from 'interfaces/service/models/ICliente';
import IMedico from 'interfaces/service/models/IMedico';
import IProducto from 'interfaces/service/models/IProducto';
import IVisitaHospital from 'interfaces/service/models/IVisitaHospital';

export class AppDexieDb extends Dexie {
  medicos!: Table<IMedico>; 
  clientes!: Table<ICliente>;
  productos!: Table<IProducto>;
  actividades_registradas!: Table<IActividad>;
  vis_hosp_registradas!: Table<IVisitaHospital>;

  constructor() {
    super('system-db');
    this.version(1).stores({
      medicos: '++id, nombre, apellido', // Primary key and indexed props
      clientes: '++id, nombre',
      productos: '++id, nombre',
      actividades_registradas: '++id,fecha',
      vis_hosp_registradas: '++id',
    });
  }
}

export const db = new AppDexieDb();