import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IUser from "../interfaces/service/models/IUser";
import ILoginResponse from "../interfaces/service/responses/ILoginResponse";

export async function loginRequest(email: string, password: string){
    const result = await axios.post<ILoginResponse>(API_RESOURCES.AUTH.BASE + '/login',{
        email,
        password
    })

    return result
}

export async function getCurrentUser(){
    const result = await axios.post<IUser>(API_RESOURCES.AUTH.BASE + '/me')
    return result
}