import React from 'react'
import { Button, ButtonProps, Spinner, SpinnerProps } from 'react-bootstrap'

type ButtonWithSpinner = {
    isLoading?: boolean
    buttonProps?: ButtonProps,
    spinnerProps?: SpinnerProps
    children?: React.ReactNode,
    enabledWhenLoading?: boolean

}

const ButtonWithLoading = ({
    isLoading,
    buttonProps = {
        variant: "primary"
    },
    spinnerProps = {
        as: "span",
        animation: "border",
        size: "sm"
    },
    children,
    enabledWhenLoading
}: ButtonWithSpinner) => {
    return (
        <Button disabled={isLoading && !enabledWhenLoading} {...buttonProps}>
            {isLoading && <Spinner {...spinnerProps} />}
            <span className="ms-1">
                {children}
            </span>
        </Button>
    )
}

export default ButtonWithLoading