import axios from "axios"
import API_RESOURCES from "constants/service-resources"
import IAsignacionProductos from "interfaces/service/models/IAsignacionProductos"
import IPaginatedResponse from "interfaces/service/models/IPaginatedResponse"
import IAsignarProductosAVisitadorRequest from "interfaces/service/requests/IAsignacionRequest"
import IPaginatedRequest from "interfaces/service/requests/IPaginatedRequest"
import qs from "qs"

export async function assingToUserByLot(data: IAsignarProductosAVisitadorRequest) {
    const result = await axios.post(API_RESOURCES.ASIGNACIONES.BASE, data)
    return result
}

export async function getAsignaciones(params: IPaginatedRequest) {
    const result = await axios.get<IPaginatedResponse<IAsignacionProductos>>(API_RESOURCES.ASIGNACIONES.BASE, {
        params: {
            ...params,
        },
        paramsSerializer: (pr: any) => qs.stringify(pr)
    })
    return result.data
}